import axios from './index'
// 判断该次集训是否需要前测
export const getMemberType = params => {
    return axios.post(`api/8300/memberType/getMemberType`, params)
}
// 查询用户会员信息
export const queryVipInformation = params => {
    return axios.post(`api/8210/user/selectUserMember`, params)
}

// 支付宝获取支付二维码
export const getQRzfb = params => {
    return axios.post(`api/8100/aliPay/aliPayCreate`,params)
}
// 微信获取支付二维码
export const getQRwx = params => {
    return axios.post(`api/8100/wxPay/wxPayCreate`, params)
}

// 新增订单
export const newOrder = params => {
    return axios.post(`api/8240/orders/insertOrders`,params)
}
// 查询订单支付状态
export const orderStatus = params => {
    return axios.post(`api/8240/orders/selectOrdersDdzt`,params)
}
//分页查询我的订单
export const orderQueryMy = params => {
    return axios.post(`api/8240/orders/selectMyOrdersPage`,params)
}
//分页查询所有订单
export const orderQueryAll = params => {
    return axios.post(`api/8240/orders/selectOrdersPage`,params)
}
// 修改订单
export const orderUpdate = params => {
    return axios.post(`api/8240/orders/updateOrders`,params)
}
