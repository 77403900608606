<template>
  <div>

    <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
        style="height: 0;"
    >
      <div class="dialog-content">
        <el-form ref="form" :model="formData" :rules="formRules" label-width="25px" style="height: 220px;background-color: #fff;width: 400px;margin-left:30px;
        backdrop-filter: blur(2px);">
          <el-form-item prop="mm" style="padding-top: 30px">
            <el-input v-model="formData.mm" placeholder="请输入新密码"
                      style="width:330px;background-color:#f0f2f5;padding: 10px;margin-bottom: 10px"
                      :type="showPassword ? 'text' : 'password'"></el-input>
            <svg-icon :icon="showPassword ? 'icon1' : 'icon2' " @click.native="togglePasswordVisibility"
                      style="position: absolute; top:40px ; right:60px;display: inline-block"/>
          </el-form-item>
          <el-form-item prop="yzm">
            <div style="display: flex;width: 350px;height: 60px">
              <el-input placeholder="请输入验证码"
                        style="width:40%;background-color:#f0f2f5;padding: 10px;margin: 5px 15px 10px 0 "
                        v-model="formData.yzm"
                        clearable></el-input>
              <el-button
                  style="color: orange;margin-top: 5px;margin-bottom: 10px;border: orange;cursor: pointer;width: 60%"
                  @click="obtainYzm"
                  :disabled="disableButton">
                {{ buttonText }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose" style="margin-right: 20px;color: gray">取 消</el-button>
        <el-button @click="handleSubmit" style="color: gray">确 定</el-button>
      </div>
      <svg-icon :icon=" 'icon4' " style="position: absolute;top:16vh;left: 345px;cursor: pointer" @click.native="closeFn"></svg-icon>
    </el-dialog>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
  </div>
</template>

<script>
import {sendOnline, alterPassword} from "@/api/login";

export default {
  data() {
    return {
      dialogInfos: {
        width: '24vw',
        isShow: false //是否开启进入阅读的动画
      },
      dialogMsgs: '',
      dialogVisible: false,
      showPassword: false, // 是否显示密码
      buttonText: '获取验证码',
      disableButton: false,
      yzmResult: '',
      formData: {
        mm: '',
        yzm: '',
        yhlx: 'ydUser',
        yhid: sessionStorage.getItem('yhid'),
        yhm: sessionStorage.getItem('sjh')
      },
      formRules: {
        mm: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        yzm: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async obtainYzm() {
      const {resultCode, result} = await sendOnline({phone: this.formData.yhm})
      if (resultCode == 200) {
        this.yzmResult = result
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取成功`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      } else {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取失败`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      }

      // 禁用按钮并设置倒计时
      this.disableButton = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.disableButton = false;
          this.buttonText = '获取验证码';
        } else {
          this.buttonText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    closeFn(){
      this.handleClose()
    },
    handleSubmit() {
      let param = {
        appIdList: [],
        classIdList: [],
        roleIdList: [],
        studentsIdList: [],
        tenantsIdList: [],
        user: {yhid: sessionStorage.getItem('yhid'), mm: this.formData.mm}
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.yzm === '') {
            this.dialogInfos.isShow = true
            this.dialogMsgs = `请输入验证码`
            setTimeout(() => {
              this.dialogInfos.isShow = false
            }, 2000)
            return
          }
          if (this.formData.yzm != this.yzmResult) {
            this.dialogInfos.isShow = true
            this.dialogMsgs = `请输入正确的验证码`
            setTimeout(() => {
              this.dialogInfos.isShow = false
            }, 2000)
            return
          }
          alterPassword(param).then((res) => {
            if (res.resultCode === 200) {
              this.dialogInfos.isShow = true
              this.dialogMsgs = `修改成功,请重新登录`
              this.handleClose()
              setTimeout(() => {
                this.dialogInfos.isShow = false
                this.$router.push('/')
              }, 2000)
            } else {
              this.dialogInfos.isShow = true
              this.dialogMsgs = `修改失败`
              setTimeout(() => {
                this.dialogInfos.isShow = false
              }, 2000)
            }
          })
        } else {
          return false;
        }
      })

    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  margin-left: -30px;
}

::v-deep .el-dialog__wrapper {
  margin-left: 38%;
  color: #fff;
  width: 400px;
}

.dialog-footer {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 15px;
  text-align: right;
  background-color: #f0f2f5;
}

::v-deep .el-dialog__footer {
  padding: 0;
}

::v-deep .el-dialog__title {
  color: white;
}

::v-deep .el-dialog__header {
  background-color: #2ecbff;
  padding: 15px 15px 14px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

::v-deep .el-dialog__close {
}

::v-deep .el-dialog__headerbtn {
  background-color: #2ecbff;
  border: white;
  margin-left: 260px;
  cursor: pointer;
}

::v-deep .el-form-item {
  height: 80px;
}
</style>
