<template>
    <div id="selection">
        <div class="bottom-box">
            <sw-button v-for="item in selectionList" :key="item.name" class="sw-btn" @click="handleRouter(item)" >{{item.name}}</sw-button>
        </div>
        <sw-button
            :sound="false"
            @click="dialogInfo.isShow = true"
            class="sw-btn-back"
            title="返回"
        >
            <i class="sw-btn-back-icon1"></i>
        </sw-button>
        <sw-dialog :dialogInfo="dialogInfo"></sw-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            selectionList: [
                {name:'快速阅读',id: '2'},
            ],
            dialogInfo: {
                msg: "确认退出训练吗？",
                isShow: false,
                hasIcon: true,
                icon: "？"
            }
        }
    },
    methods: {
        handleRouter(item) {
            this.$router.push({
                path: `/sw/gameList`,
                query: {
                    id: item.id,
                    name: item.name
                }
            });
        },
    }
};
</script>

<style lang="scss">
#selection {
    .top-box {
        box-sizing: border-box;
        height: 30vh;
        padding-top: 6vh;
    }
    .bottom-box {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .sw-btn{
        padding: 4vh 13vw;
        font-size: 3vw;
    }
    
}
</style>