<template>
  <el-dialog title="温馨提示：会员服务为虚拟产品，支付成功后不支持退款" :visible.sync="drawer" :show-close="false">
    <svg-icon :icon=" 'icon4' " style="position: absolute;top:1vh;right: 1.5vw;cursor: pointer"
              @click.native="closeFn"></svg-icon>
    <div class="box" v-loading="loading">
      <div class="vip">
        <div class="vip-grid">
          <div v-for="(vipItem, index) in vipItems" :key="index" class="vip-item" @click="openDrawer(vipItem)">
            <div class="item-banner">{{ '有效期' + vipItem.hyts + '天' }}</div>
            <div class="item-title">{{ vipItem.hymc }}</div>
            <div class="item-amount">{{ '￥' + vipItem.hyjg }}</div>
            <div class="form-tips line-through">{{ '￥' + (vipItem.hyjg * 2) }}</div>
            <el-button class="item-button button-gold" type="primary">
              <span v-if="!fellow">立即开通</span>
              <span v-else>立即续费</span>
            </el-button>
            <div class="item-rights">
              <div class="rights-title">会员权益：</div>
              <ul class="rights-list">
                <li v-for="(i, index) in myAppList" :key="index" style="padding-bottom: 10px">
                  {{ i }} <i class="el-icon-check" style="color: green; float: right"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Payment ref="pay"/>
    </div>

  </el-dialog>
</template>

<script>
import Payment from './payment.vue'
import {getMemberType} from '@/api/vip'
import {mapState} from "vuex";

export default {
  name: 'vip',
  components: {Payment},
  data() {
    return {
      drawer: false,
      loading: false,
      myAppList: ['能力测试', '阅读训练', '必读书籍'],
      vipItems: []
    };
  },
  created() {
    this.getMemberTypeFn()
    console.log(this.fellow, 1111)
  },
  computed: {
    ...mapState({
      fellow: state => state.speedReading.fellow
    }),
  },

  methods: {
    closeFn(){
      this.drawer=false
    },
    async getMemberTypeFn() {
      this.loading = true
      const {result} = await getMemberType({hyxt: 8})
      this.loading = false
      this.vipItems = result
    },
    openDrawer(vipItem) {
      this.$nextTick(() => {
        this.$refs.pay.setSelectedPackage(vipItem.hymc, vipItem.hyjg, vipItem.hylxid);
        this.$refs.pay.dialogVisible = true;
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.el-dialog__wrapper{
  position: relative;
  width:1000px;
  margin: auto;
}
::v-deep .el-dialog{
  background-color: #fff;
}
::v-deep .el-dialog__header {
  padding: 15px 0 15px 15px;
  background-color: #2ecbff;
}

::v-deep .el-dialog__title {
  text-align: center !important;
  color: #FFFFFF;
}

.box {
  width: 100%;
  height: 100%;
}

.vip {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.vip-grid {
  width: 1240px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.vip-item {
  background-color: #edeff36b;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e5e5ea;
  margin: 20px;
  padding: 20px;
}

.item-banner {
  font-size: 12px;
  background-color: red;
  color: white;
  text-align: center;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.item-title {
  font-size: 18px;
  color: black;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.item-amount {
  color: red;
  font-weight: bolder;
  font-size: 25px;
  margin-bottom: 10px;
  text-align: center;
}

.item-button {

  width: 100%;
  margin-bottom: 10px;
}

.form-tips {
  font-size: 15px;
  line-height: 1.5rem;
  margin-top: 0.25rem;
  text-align: center;
  padding-bottom: 20px;
}

.line-through {
  text-decoration-line: line-through;
}

.button-gold {
  background-image: linear-gradient(86.5deg, #fceee0, #ffc9a0);
  color: #6b3d1e;
  border: none;
  margin-bottom: 10px;
}

.item-rights {
  margin-top: 20px;
}

.rights-title {
  font-size: 14px;
  padding-bottom: 30px;
}

.rights-list {
  font-size: 14px;
  color: black;
  list-style-type: disc;
}
</style>



