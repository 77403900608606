<template>
  <div class="sw-home-contanier">
    <div class="sw-home">
      <div class="sw-title">{{ currentApp.title }}</div>
      <sw-button class="sw-btn" @click="handleClick">开始训练</sw-button>
    </div>
    <div class="sw-footer">
      <span class="sw-version">{{ $store.state.speedReading.version }}</span>
    </div>
    <sw-back-out :backPre="backPre"></sw-back-out>
  </div>
</template>

<script>
import SwConfig from './SwConfig.js'

export default {
  data() {
    return {
      backOut: false,
      id: this.$route.query.app.indexOf('speedReading/') != -1 ? '2' : '1',
      currentApp: {app: '', title: ""},

      backPre: {
        path: "/sw/gameList",
        query: {
          id: this.$route.query.app.indexOf('speedReading/') != -1 ? '2' : '1',
          name: this.$route.query.app.indexOf('speedReading/') != -1 ? '快速阅读' : '思维训练营',
        }
      }
    };
  },
  created() {
    if (SwConfig.appList[this.id].filter(l => l.app == this.$route.query.app).length) {
      if (this.$store.state.speedReading.currentCheckPoint) {
        if (SwConfig.appList[this.id].filter(l => l.type == this.$store.state.speedReading.currentCheckPoint).length) {
          this.currentApp = SwConfig.appList[this.id].filter(l => l.type == this.$store.state.speedReading.currentCheckPoint)[0]
        }
      } else {
        this.currentApp = SwConfig.appList[this.id].filter(l => l.app == this.$route.query.app)[0]
      }
    }
  },
  methods: {
    handleClick() {
      console.log(this.currentApp.app, this.$route.query)
      this.$router.push({
        path: '/sw/' + this.currentApp.app,
        query: {
          lxid: this.$route.query.lxid,
          lxmc:this.$route.query.lxmc
        }
      })
    },
  }
};
</script>