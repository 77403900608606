export default {
    /* 开发IP */
    // serverPath: 'http://39.98.191.75:8825',
    /* 客户IP */
    appList: {
        '1': [{
            app: 'instantaneous',
            title: "瞬间闪视",
        }, 
        // {
        //     app: '',
        //     title: "眼肌训练",
        // }, 
        {
            app: 'square',
            title: "记忆方格",
        },
        {
            app: 'verbalReasoning',
            title: "词语推理",
        },
        {
            app: 'holisticPerception',
            title: "整体感知",
        }],
        '2': [
        //     {
        //     app: 'speedReading/evaluation',
        //     title: "阅读测评",
        //     type: 2
        // }, 
        {
            app: 'speedReading/evaluation',
            title: "能力测试",
            type: 1,
        },{
            app: 'speedReading/training',
            title: "阅读训练",
            type: 3,
        },  {
            app: 'speedReading/training',
            title: "必读书籍",
            type: 4,
        }]
    }
}