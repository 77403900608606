<template>
  <div>
    <el-dropdown style="text-align: right;margin-top: 20px;margin-right: 10px;padding: 10px" @command="returnLogin">
      <div style="cursor: pointer; display: flex; align-items: flex-end;float: right">
        <el-button style="margin-right: 20px">
          <svg-icon :icon="'icon14'" style="padding-top: 4px;" v-if="!fellow" />
          <svg-icon :icon="'icon15'" style="padding-top: 4px;" v-else/>
        </el-button>
        <span style="color: #FFFFFF; font-size: 25px">{{ xm }}</span>
      </div>
      <el-dropdown-menu slot="dropdown"
                        style="background-color: #fff;cursor: pointer;color: #2ecbff;margin-top: 80px;width: 80px;padding: 10px;margin-right: 75px">
        <el-dropdown-item command="1" style="padding-bottom: 10px">修改密码</el-dropdown-item>
        <el-dropdown-item command="myVip" v-if="fellow" style="padding-bottom: 10px">我的会员</el-dropdown-item>
        <el-dropdown-item command="goVip" v-else style="padding-bottom: 10px">开通会员</el-dropdown-item>
        <el-dropdown-item command="order" style="padding-bottom: 10px">我的订单</el-dropdown-item>
        <el-dropdown-item command="2">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <AlterPassword ref="alter"/>
    <Vip ref="membership"/>
    <MyVip ref="myVip"/>
  </div>
</template>

<script>
import AlterPassword from '@/components/aboutPassword/alterPassword.vue'
import Vip from '@/views/vip/vip.vue'
import MyVip from "@/views/vip/myVip.vue";
import {mapState} from "vuex";

export default {
  components: {AlterPassword, Vip, MyVip,},
  data() {
    return {
      xm:sessionStorage.getItem('yhmc')
    };
  },
  computed: {
    ...mapState({
      fellow: state => state.speedReading.fellow
    }),
  },
  methods: {
    returnLogin(command) {
      if (command == 1) {
        //修改密码
        this.$refs.alter.dialogVisible = true
      } else if (command == "myVip") {
        this.$refs.myVip.dialogVisible = true
      } else if (command === "goVip") {
        this.$refs.membership.drawer = true
      } else if (command === "order") {
        this.$router.push('/sw/orderList')
      } else {
        //退出登录
        this.$router.push('/')
        sessionStorage.clear()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>