<template>
  <el-dialog
      :title="title"
      :visible="dialogVisible"
      :width="width"
      @close="handleClose"
      @closed="reset"
      append-to-body
      :show-close="false"
  >
    <svg-icon :icon=" 'icon4' " style="cursor: pointer;position: relative;top: -45px;right: -350px"
              @click.native="closeFn" v-if="!showPaymentMethod"></svg-icon>
    <svg-icon :icon=" 'icon4' " style="cursor: pointer;position: relative;top: -45px;right: -420px"
              @click.native="closeFn" v-else></svg-icon>
    <el-form v-if="!showPaymentMethod" ref="paymentForm" label-width="80px">
      <el-form-item label="会员姓名">
        <span class="item-value">{{ xm }}</span>
      </el-form-item>
      <el-form-item label="会员套餐">
        <span class="item-value">{{ selectedPackage }}</span>
      </el-form-item>
      <el-form-item label="支付金额">
        <span class="item-amount">{{ paymentAmount }}</span>
      </el-form-item>
      <el-divider></el-divider>
      <div style="text-align: center;padding-bottom:20px">
        <el-button class="btn" type="primary" @click="goPay">
          <div>
            <svg-icon icon="icon9" style="display: inline;padding-right: 10px"/>
            <span>订单确认</span>
          </div>
        </el-button>
      </div>
    </el-form>

    <el-form v-if="showPaymentMethod" label-width="100px">
      <el-form-item label="订单编号">
        <span style="color: red">{{ orderNo }}</span>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="支付方式">
      </el-form-item>
      <div style="display: flex;justify-content: space-between;padding-left: 25px">
        <div class="payment-buttons">
          <el-button
              :class="{ 'active': selectedPaymentMethod === '支付宝' }"
              class="payment-button"
              @click="selectFn('支付宝')"
          >
            <svg-icon icon="icon13" style="display: inline;"/>
            <span>&nbsp;&nbsp;支付宝</span>
          </el-button>
          <el-button
              :class="{ 'active': selectedPaymentMethod === '微信' }"
              class="payment-button"
              @click="selectFn('微信')"
          >
            <svg-icon icon="icon5" style="display: inline;"/>
            <span>&nbsp;&nbsp;微信&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </el-button>
        </div>
        <div class="payment-container">
          <span class="payment-text">{{ selectedPaymentMethod }}扫一扫支付</span>
          <div v-loading="loading" class="payment-qr-code-img">
            <img :src="paymentUrl" alt="二维码" class="payment-qr-code"/>
          </div>
        </div>
      </div>
    </el-form>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
  </el-dialog>
</template>

<script>
import {getQRzfb, getQRwx, queryVipInformation, newOrder, orderStatus} from '@/api/vip'
import QRCode from 'qrcode-generator';

export default {
  data() {
    return {
      xm: sessionStorage.getItem('yhmc'),
      dialogInfos: {
        width: '24vw',
        isShow: false //是否开启进入阅读的动画
      },
      dialogMsgs: '',
      loading: false,
      width: '400px',
      title: '订单确认',
      showPaymentMethod: false,
      paymentUrl: '', // 支付二维码
      dialogVisible: false,
      selectedPackage: "", // 选中的会员套餐
      paymentAmount: "",
      selectedPaymentMethod: "支付宝",
      ddsp: '',
      ddid: '',
      orderNo: '',
      phone: sessionStorage.getItem("sjh"),
      paymentStatusTimer: undefined
    };
  },
  beforeDestroy() {
    // 组件销毁前停止轮询
    this.stopPaymentStatusPolling();
  },
  methods: {
    closeFn() {
      this.dialogVisible = false
    },
    // 生成二维码图片
    generateQrCode(qrCodeUrl) {
      const qr = QRCode(4, 'L');
      qr.addData(qrCodeUrl);
      qr.make();
      // 获取二维码图片链接
      let imgDataUrl = qr.createDataURL();
      this.paymentUrl = imgDataUrl;
    },
    selectFn(value) {
      this.selectedPaymentMethod = value
      this.loadQRCode(value)
    },
    // 选中那一项的会员套餐
    setSelectedPackage(title, amount, ddsp) {
      this.selectedPackage = title;
      this.paymentAmount = amount;
      this.ddsp = ddsp
    },
    handleClose() {
      this.dialogVisible = false;
      this.stopPaymentStatusPolling(); // 停止轮询
    },
    reset() {
      this.selectedPaymentMethod = '支付宝'
      this.showPaymentMethod = false
      this.width = '400px'
      this.title = '订单确认'
      this.paymentUrl = ''
    },
    // 前往支付
    async goPay() {
      try {
        const {result, resultCode, resultDesc} = await newOrder({
          ddjg: this.paymentAmount,
          ddlx: 1,
          ddsp: this.ddsp,
          spsl: 1,
          cjr: sessionStorage.getItem('yhid')
        });
        if (resultCode === 200) {
          this.orderNo = result.ddbh;
          this.ddid = result.ddid;
          this.showPaymentMethod = true;
          this.selectFn('支付宝');
          this.startPaymentStatusPolling();
          this.width = '480px';
          this.title = '立即支付';
        } else {
          this.dialogInfos.isShow = true
          this.dialogMsgs = resultDesc
          setTimeout(() => {
            this.dialogInfos.isShow = false
            this.dialogVisible = false;
          }, 2000)

        }
      } catch (error) {
        this.dialogInfos.isShow = true
        this.dialogMsgs = "您有同类型的未支付的订单，请勿重复下单"
        setTimeout(() => {
          this.dialogInfos.isShow = false
          this.dialogVisible = false;
        }, 2000)
      }
    },


    // 获取二维码接口
    async loadQRCode(value) {
      this.loading = true
      const isZfb = value === '支付宝'
      try {
        const {result: {qrcode}} = await (isZfb ? getQRzfb : getQRwx)({
          ddbh: this.orderNo,
          price: isZfb ? this.paymentAmount : Math.round(this.paymentAmount * 100),
        })
        const qrCodeUrl = qrcode;
        this.generateQrCode(qrCodeUrl);
        this.loading = false

      } catch (error) {
        // 处理错误
        this.dialogInfos.isShow = true
        this.dialogMsgs = error
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      }
    },
    // 开始轮询调用支付状态
    startPaymentStatusPolling() {
      this.paymentStatusTimer = setInterval(() => {
        this.paymentStatus();
      }, 2000);
    },
    // 停止轮询
    stopPaymentStatusPolling() {
      clearInterval(this.paymentStatusTimer);
    },
    // 支付状态接口
    async paymentStatus() {
      const {result} = await orderStatus({ddid: this.ddid});
      if (result === 2) {
        this.stopPaymentStatusPolling(); // 停止轮询
        this.dialogInfos.isShow = true
        this.dialogMsgs = '支付成功'
        setTimeout(() => {
          this.dialogInfos.isShow = false
          this.dialogVisible = false;
          this.paymentUrl = ''
          this.showPaymentMethod = false
          this.width = '400px'
          this.title = '订单确认'
        }, 2000)
        this.$emit('load')
        const {result: {sxsj}} = await queryVipInformation({yhid: sessionStorage.getItem('yhid')});
        this.$store.commit('speedReading/setVip', true);
        this.$store.commit('speedReading/setVipTime', sxsj);

      }
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  height: 80px;
  display: flex;
  margin-left: 20px;
}

::v-deep .el-dialog {
  margin: -45vh auto !important;

  background-color: #FFFFFF !important;
}

::v-deep .el-dialog__header {
  padding: 15px 15px 14px;
  background-color: #2ecbff;
  border-radius: 0;
}

::v-deep .el-dialog__title {
  text-align: center !important;
  color: #FFFFFF;
}

.payment-buttons {
  display: flex;
  flex-direction: column;
}

.payment-button {
  cursor: pointer;
  width: 130px;
  margin-left: 0 !important;
  margin-bottom: 10px;
}

.btn {
  cursor: pointer;
  width: 130px;
  height: 40px;
  div {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.payment-button.active {
  border: 2px solid #409eff;
}

.payment-container {
  border: 1px solid #409eff;
  border-radius: 10px;
  text-align: center;
  width: 190px;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-right: 10px;

  .payment-text {
    color: #409eff;
    font-weight: bold;
  }

  .payment-qr-code-img {
    //width: 180px;
    height: 180px;

    img[src=""], img:not([src]) {
      //display: none;
      opacity: 0;
    }

    .payment-qr-code {
      width: 180px;
      height: 180px;
      margin: 0 auto;
      transition: opacity 1s;
      opacity: 1;
    }
  }

}


.item-value {
  margin-left: -40px;
}

.item-amount {
  margin-left: -40px;
  color: red;
  font-size: 22px;
}
.el-dialog__wrapper{
  position: relative;
  width:1000px;
  margin: auto;
}
</style>



