<template>
  <div class="login">
    <div class="bg" id="bg">
      <div class="content" id="content">
        <div class="rightLogin" style="margin-left:40px" v-show="isShowLogin">
          <div class="h1">快速阅读</div>
          <div v-if="showInput">
            <el-input prefix-icon="el-icon-user-solid" v-model="username" placeholder="请输入手机号" class="input"
                      autocomplete="off" auto-complete="new-password" style="background-color: #fff;"/>
            <el-input prefix-icon="el-icon-unlock" @keyup.enter.native="login" v-model="password"
                      placeholder="请输入密码" type="password" class="input" autocomplete="off"
                      auto-complete="new-password" style="background-color: #fff;"/>
          </div>
          <div v-if="!showInput">
            <el-input prefix-icon="el-icon-mobile-phone" v-model="myPhone" placeholder="请输入手机号" class="input"
                      autocomplete="off" auto-complete="new-password" style="background-color: #fff;"/>
            <el-input prefix-icon="el-icon-warning-outline" @keyup.enter.native="login" v-model="myYzm"
                      placeholder="请输入验证码" class="input" autocomplete="off" auto-complete="new-password"
                      style="background-color: #fff;"/>
          </div>
          <el-button @click="login"  style="margin:5px 25px 10px 5px;color: white; background-color: orange;width: 150px;border: #fff;cursor: pointer;font-size: 30px;border-radius: 20px;padding: 5px;text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);">登 录
          </el-button>
          <el-button  type="primary" @click="showRegistrationDialog"   style="color: white; background-color: orange;width: 150px;border: #fff;cursor: pointer;font-size: 30px;border-radius: 20px;padding: 5px;text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);"><span>注册</span></el-button>
          <div class="wjmm">
            <!--            <el-button @click="getCode" style="color: white; background-color: lightslategrey; border-radius: 15% ">-->
            <!--              <span>扫码登录</span></el-button>-->
            <!--            <span style="padding:0 50px 0 50px;">|</span>-->
            <el-button v-if="showInput"
                       style="color: white; background-color: #14b6ea24;width: 120px;border: #fff;cursor: pointer;font-size: 22px "
                       @click="textMessage"><span>短信登录</span>
            </el-button>
            <el-button v-if="!showInput"
                       style="color: white; background-color: #14b6ea24;width: 120px;border: #fff;cursor: pointer;font-size: 22px "
                       @click="textMessage"><span>账号登录</span>
            </el-button>

          </div>

        </div>
        <div v-if="showInput && !isShowForm">
          <el-button
              style="color: orange; background-color: #fff;position: relative;top: -225px
             ;left:285px ;border: #fff;cursor: pointer;margin-top: 5px"
              @click="retrieve"
              :class="{ 'fade-in': showButton }" size="mini">
            <span>忘记密码?</span></el-button>
        </div>
        <div v-if="!showInput && !isShowForm">
          <el-button
              style="color: orange; background-color: #fff;border: #fff;cursor: pointer;position: relative;top: -225px;left:275px;margin-top: 5px"
              @click="obtain"
              :disabled="byTime" :class="{ 'fade-in': showButton }" type="text">
            {{ timeText }}
          </el-button>
        </div>
        <div class="rightWx" v-show="isShowForm">
          <div class="wx_dialog_toiast_delltet" id="login_container"></div>
          <el-button class="wx_dialog_button_delete" @click="close_wchat_qrcode"
                     style="color: white; background-color: lightslategrey; border-radius: 15% ">账号登录
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog title="注册账号" :visible.sync="registrationDialogVisible"
               :before-close="handleCloseRegistrationDialog" style="height: 0;">
      <el-form :model="registrationForm" :rules="registrationFormRules" ref="registrationForm" label-width="25px"
               style="height: 380px;background-color: #fff;
        backdrop-filter: blur(2px);">
        <el-form-item prop="xm" style="padding-top: 30px">
          <el-input v-model="registrationForm.xm" placeholder="请输入姓名" clearable
                    style="width:330px;background-color: #f0f2f5;padding: 10px;margin-bottom: 10px"></el-input>
        </el-form-item>
        <el-form-item prop="sjh">
          <el-input v-model="registrationForm.sjh" placeholder="请输入手机号" clearable
                    style="width:330px;background-color: #f0f2f5;padding: 10px;margin-bottom: 10px"></el-input>
        </el-form-item>
        <el-form-item prop="yzm">
          <div style="display: flex;width: 350px;height: 60px">
            <el-input placeholder="请输入验证码"
                      style="background-color: #f0f2f5;padding: 10px;margin: 5px 15px 10px 0;width: 40% "
                      v-model="registrationForm.yzm"
                      clearable></el-input>
            <el-button
                style="color: orange;margin-top: 5px;margin-bottom: 10px;border: orange;cursor: pointer;width: 60% "
                @click="obtainYzm" :disabled="disableButton">
              {{ buttonText }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="mm">
          <el-input v-model="registrationForm.mm" placeholder="请输入密码" :type="showPassword ? 'text' : 'password'"
                    style="width:330px;background-color: #f0f2f5;padding: 10px;margin-top: 10px;margin-bottom: 10px"
                    autocomplete="new-password"></el-input>
          <svg-icon :icon="showPassword ? 'icon1' : 'icon2' " @click.native="togglePasswordVisibility"
                    style="position: absolute; top:290px ; right:60px;display: inline-block"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseRegistrationDialog" style="margin-right: 20px;color: gray">取 消</el-button>
        <el-button @click="register" type="primary" style="color: white">确 定</el-button>
      </div>
      <svg-icon :icon=" 'icon4' " style="position: absolute;top:16vh;left: 345px;cursor: pointer"
                @click.native="closeFn"></svg-icon>
    </el-dialog>
    <Forget ref="forget"/>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
  </div>
</template>

<script>
import {loginOnline, loginMobile, userOnline, sendOnline} from "@/api/login";
// import SelectArea from '../components/area/select_area_schoolManagement.vue'
import Forget from '../components/aboutPassword/forget.vue'
import {Logger} from "sass";
import {queryVipInformation} from '@/api/vip'
export default {
  name: "login",
  components: {Forget},
  data() {
    return {
      dialogInfos: {
        width: '24vw',
        isShow: false //是否开启进入阅读的动画
      },
      dialogMsgs: '',
      showInput: true,
      byTime: false,
      timeText: '获取验证码',
      yzmRes: '',
      myPhone: '',
      myYzm: '',
      showButton: true,
      baseImgData: ["41px", "265px", "56px", "316px", "224px", "51px", "311px"], // 图标下落点top值
      username: "", // 用户名
      password: "", // 密码
      disableButton: false,
      yzmResult: '',
      buttonText: '获取验证码',
      showPassword: false, // 是否显示密码
      registrationDialogVisible: false, // 注册对话框可见性
      isShowForm: false,
      isShowLogin: true,
      registrationForm: {
        xm: "",
        xb: "",
        ssqy: "",
        sjh: "",
        mm: "",
        yhm: '',
        yhlx: 'ydUser',
        yzm: '',
      },
      registrationFormRules: {
        xm: [
          {required: true, message: "请输入姓名", trigger: "blur"}
        ],
        xb: [
          {required: true, message: "请选择性别", trigger: "change"}
        ],
        ssqy: [
          {required: true, message: "请输入所属区域", trigger: "blur"}
        ],
        sjh: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确，请重新输入", trigger: "blur"}
        ],
        mm: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ],
        yzm: [
          {required: true, message: "请输入验证码", trigger: "blur"}
        ],
      }
    };
  },
  created() {
    sessionStorage.clear()
  },
  mounted() {
    this.fadeInButton();
    let bases = document.getElementsByClassName("base"); // 所有基础图标
    let shadows = document.getElementsByClassName("shadow"); // 所有阴影图标
    let baseTime = 4000; // 开始下落的时间
    let shadowTime = 4500; // 阴影出现的开始时间
    let downTimeSpace = 500; // 下落的时间间隔
    // 基础图标下落
    for (let i = 0; i < bases.length; i++) {
      setTimeout(() => {
        bases[i].style.top = this.baseImgData[i];
        bases[i].style.opacity = "1";
      }, baseTime + i * downTimeSpace);
    }
    // 阴影图标出现
    for (let i = 0; i < shadows.length; i++) {
      setTimeout(() => {
        shadows[i].style.opacity = "1";
      }, shadowTime + i * downTimeSpace);
    }
  },
  methods: {
    textMessage() {
      this.showInput = !this.showInput
    },
    async obtain() {
      if (!this.myPhone) {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `请先输入手机号`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
        return
      }
      const {resultCode, result} = await sendOnline({phone: this.myPhone})
      if (resultCode == 200) {
        this.yzmRes = result
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取成功`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
        return

      } else {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取失败`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
        return
      }

      // 禁用按钮并设置倒计时
      this.byTime = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.byTime = false;
          this.timeText = '获取验证码';
        } else {
          this.timeText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    fadeInButton() {
      setTimeout(() => {
        this.showButton = false;
      }, 600);
    },
    retrieve() {
      this.$refs.forget.dialogVisible = true
    },
    close_wchat_qrcode() {
      this.isShowForm = false
      this.isShowLogin = true
      this.showButton = true
      setTimeout(() => {
        this.showButton = false
      }, 500)
    },
    getCode() {
      this.isShowForm = true;
      this.isShowLogin = false;
      new window.WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wx19ee831441ad4be5",
        scpoe: "snsapi_login",
        redirect_uri: "https://aiyb.fefsfc.com",
        state: Math.random(),
        style: "white",
        href: "data:text/css;base64,Lyogd3hsb2dpbi5jc3MgKi8NCi5pbXBvd2VyQm94IC50aXRsZSwgLmltcG93ZXJCb3ggLmluZm97DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoNCi5pbXBvd2VyQm94IC5xcmNvZGV7DQogIG1hcmdpbi10b3A6IDIwcHg7DQp9",
      })
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async obtainYzm() {
      if (!this.registrationForm.sjh) {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `请先输入手机号`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      }
      const {resultCode, result} = await sendOnline({phone: this.registrationForm.sjh})
      if (resultCode == 200) {
        this.yzmResult = result
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取成功`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      } else {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取失败`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      }
      // 禁用按钮并设置倒计时
      this.disableButton = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.disableButton = false;
          this.buttonText = '获取验证码';
        } else {
          this.buttonText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    // 所属区域change事件
    areaChange(val) {
      this.$set(this.registrationForm, 'ssqy', val.area_value)
    },
    async login() {
      if (this.showInput) {
        const {username, password} = this;

        if (!username) {
          this.dialogInfos.isShow = true
          this.dialogMsgs = `手机号不能为空`
          setTimeout(() => {
            this.dialogInfos.isShow = false
          }, 2000)
          return
        }

        if (!password) {
          this.dialogInfos.isShow = true
          this.dialogMsgs = `密码不能为空`
          setTimeout(() => {
            this.dialogInfos.isShow = false
          }, 2000)
          return
        }

        try {
          const res = await loginOnline({yhm: username, mm: password, yhlx: 'ydUser'});

          if (res.success) {
            const {yhm, yhid, xm, xb, yhlx, userToken, sjh} = res.result[0];
            const userInfo = {yhm, yhid, xm, xb, yhlx};
            this.$store.commit('speedReading/setXm', xm);
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            sessionStorage.setItem("token", userToken);
            sessionStorage.setItem("sjh", sjh);
            sessionStorage.setItem("yhid", yhid);
            sessionStorage.setItem('yhmc', xm)
            this.$store.commit("speedReading/setInfo", userInfo);
            this.$store.commit("speedReading/setToken", userToken);
            const vipRes = await queryVipInformation({yhid: sessionStorage.getItem('yhid')});
            if (vipRes && vipRes.result !== undefined && vipRes.result !== null && Object.keys(vipRes.result).length !== 0) {
              const vipResult = vipRes.result;
              if (Object.keys(vipResult).length !== 0) {
                const time = vipResult.sxsj
                this.$store.commit('speedReading/setVip', true);
                this.$store.commit('speedReading/setVipTime', time);
              }
            } else {
              this.$store.commit('speedReading/setVip', false);
            }
            this.$router.push({
              path: "/sw/gameList",
              query: {
                id: 2,
                name: '快速阅读'
              }
            })
          } else {
            this.dialogInfos.isShow = true
            this.dialogMsgs = res.resultDesc
            setTimeout(() => {
              this.dialogInfos.isShow = false
            }, 2000)
          }
        } catch (error) {
          console.error("登录失败：", error);
          this.dialogInfos.isShow = true
          this.dialogMsgs = `登录失败！！！`
          setTimeout(() => {
            this.dialogInfos.isShow = false
          }, 2000)
        }
      } else {
        if (!this.myPhone) {
          this.dialogInfos.isShow = true
          this.dialogMsgs = `手机不能为空`
          setTimeout(() => {
            this.dialogInfos.isShow = false
          }, 2000)
          return
        }
        if (!this.myYzm) {
          this.dialogInfos.isShow = true
          this.dialogMsgs = `验证码不能为空`
          setTimeout(() => {
            this.dialogInfos.isShow = false
          }, 2000)
          return
        }
        if (this.myYzm != this.yzmRes) {
          this.dialogInfos.isShow = true
          this.dialogMsgs = `请输入正确的验证码`
          setTimeout(() => {
            this.dialogInfos.isShow = false
          }, 2000)
          return
        }
        const res = await loginMobile({yhm: this.myPhone, yhlx: 'ydUser'})
          if (res.success) {
            console.log(res.result[0].sjh, '2323')
            const {yhm, yhid, xm, xb, yhlx, userToken, sjh} = res.result[0];
            const userInfo = {yhm, yhid, xm, xb, yhlx};
            this.$store.commit('speedReading/setXm', xm);
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            sessionStorage.setItem("token", userToken);
            sessionStorage.setItem('yhmc', xm)
            sessionStorage.setItem("yhid", yhid);
            sessionStorage.setItem("sjh", sjh);
            this.$store.commit("setInfo", userInfo);
            this.$store.commit("setToken", userToken);
            const vipRes = await queryVipInformation({yhid: sessionStorage.getItem('yhid')});
            if (vipRes && vipRes.result !== undefined && vipRes.result !== null && Object.keys(vipRes.result).length !== 0) {
              const vipResult = vipRes.result;
              if (Object.keys(vipResult).length !== 0) {
                const time = vipResult.sxsj
                this.$store.commit('speedReading/setVip', true);
                this.$store.commit('speedReading/setVipTime', time);
              }
            } else {
              this.$store.commit('speedReading/setVip', false);
            }
            this.$router.push({
              path: "/sw/gameList",
              query: {
                id: 2,
                name: '快速阅读'
              }
            })
          } else {
            this.dialogInfos.isShow = true
            this.dialogMsgs = res.resultDesc
            setTimeout(() => {
              this.dialogInfos.isShow = false
            }, 2000)
          }
      }

    },
    // 显示注册对话框
    showRegistrationDialog() {
      this.registrationDialogVisible = true;
    },
    // 注册
    register() {
      this.registrationForm.yhm = this.registrationForm.sjh
      this.$refs.registrationForm.validate(valid => {
        if (valid) {
          if (this.registrationForm.yzm != this.yzmResult) {
            this.dialogInfos.isShow = true
            this.dialogMsgs = `请输入正确的验证码`
            setTimeout(() => {
              this.dialogInfos.isShow = false
            }, 2000)
            return
          }
          userOnline({user: this.registrationForm})
              .then(res => {
                if (res.resultCode === 200) {
                  this.dialogInfos.isShow = true
                  this.dialogMsgs = `注册成功`
                  setTimeout(() => {
                    this.dialogInfos.isShow = false
                  }, 2000)
                  this.handleCloseRegistrationDialog();
                } else {
                  this.dialogInfos.isShow = true
                  this.dialogMsgs = res.resultDesc
                  setTimeout(() => {
                    this.dialogInfos.isShow = false
                  }, 2000)
                }
              })
              .catch(error => {
                this.dialogInfos.isShow = true
                this.dialogMsgs = `注册失败，请重试`
                setTimeout(() => {
                  this.dialogInfos.isShow = false
                }, 2000)
              });
        } else {
          return false;
        }
      });
    },
    closeFn() {
      this.handleCloseRegistrationDialog()
    },
    // 关闭注册对话框
    handleCloseRegistrationDialog() {
      this.$refs.registrationForm.resetFields();
      this.registrationDialogVisible = false;
    }
  },
};
</script>

<style lang="scss">

.login {
  background: url("../assets/images/bg.png");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: 100% 100%;
  overflow: hidden;
  //font-family: "";

  .bg {
    .content {
      position: absolute;
      left: 65%;
      top: 20%;

      .rightLogin {
        background-color: #66b1ff54;
        backdrop-filter: blur(2px);
        width: 330px;
        padding: 0 25px 45px 25px;

        .sw-btn {
          line-height: 5vh;
          margin: 0;
          font-size: 1.5vw;
          padding: 1.2vh 2.5vw;

          &.disabled {
            opacity: 0.5;
          }
        }

        .wjmm {
          padding: 15px 0 0 3px;
          text-align: center;
          width: 100%;
          color: white;
          display: flex;
          justify-content: space-around;
          margin-bottom: 20px;

          span {
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          }
        }

        .h1 {
          color: #fff;
          font-size: 45px;
          text-align: center;
          padding-bottom: 30px;
          padding-top: 50px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }

        .h2 {
          color: #fff; // #75b7f7;
          font-size: 25px;
          text-align: center;
          padding-bottom: 40px;
        }

        .input {
          padding: 8px 0 8px 5px;
          margin-bottom: 20px;
          height: 25px;
          font-size: 18px;
        }

        .btn {
          width: 100%;
          margin-top: 10px;
        }
      }

      .rightWx {
        float: left;
        width: 280px;
        margin-left: 50px;

        .btn {
          width: 100%;
          margin-top: 10px;
        }

        .wx_dialog_toiast_delltet {
          width: 300px;
          height: 400px;
          background: #606266;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .wx_dialog_button_delete {
          margin-left: 125px;
          color: white;
          font-size: 15px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.login {
  ::v-deep .el-button--primary {
    background-color: #2ecbff;
    border-color: #2ecbff;
  }
}

::v-deep .el-input__suffix {
  padding-bottom: 50px;
}

::v-deep .el-input__icon {
  height: 67%;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s;
}

.fade-in.show {
  opacity: 1;
}

::v-deep .el-dialog__wrapper {
  margin-left: 38%;
  color: #fff;
  width: 400px;
}

::v-deep .el-dialog__header {
  background-color: #2ecbff;
  padding: 15px 15px 14px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

::v-deep .el-dialog__title {
  color: white;
}

::v-deep .el-dialog__headerbtn {
  background-color: #2ecbff;
  border: white;
  margin-left: 260px;
  cursor: pointer;
}

.dialog-footer {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 15px;
  text-align: right;
  background-color: #f0f2f5;
}

::v-deep .el-dialog__footer {
}

::v-deep .el-form-item__error {
  color: #ff00007d;
  padding-left: 14px;
  font-size: 14px;
}

::v-deep .el-form-item {
  height: 80px;
}
</style>

