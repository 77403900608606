<template>
  <div class="sw-game-list">
    <Set/>
    <div class="sw-game-list-container">
      <sw-button class="sw-btn-lang" :sound="false">{{ $route.query.name }}</sw-button>
      <div class="sw-game-list-box">
        <dl v-for="(item, index) of appList" :key="item.lxid" @click="selectType(item, index)"
            :style="{'width': $route.query.id == 2 ?'33.3%' : '25%'}">
          <dt>
            <img class="img" :src="require(`../assets/images/gamelist${$route.query.id}${index+1}.png`)"/>
          </dt>
          <dd class="sw-text">{{ item.lxmc }}</dd>
        </dl>
      </div>
    </div>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
  </div>
</template>

<script>
import {readType} from '@/api/reading'
import Set from '@/components/setting/set.vue'
import {mapState} from "vuex";

export default {
  components: {Set},
  data() {
    return {
      backOut: false,
      appList: [],
      dialogInfos: {
        width: '24vw',
        isShow: false //是否开启进入阅读的动画
      },
      dialogMsgs: '',
    };
  },
  created() {
    this.queryType()
    this.$store.commit('speedReading/setCurrentCheckPoint', '');
    window.localStorage.removeItem('instantaneous-currentCheckPoint');
  },
  computed: {
    ...mapState({
      fellow: state => state.speedReading.fellow
    }),
  },
  methods: {

    async queryType() {
      const {result} = await readType();
      this.appList = result;
      console.log(this.appList, 1111)
    },
    selectType(item, index) {
      if (this.fellow) {
        const appPath = item.lxmc === '能力测试'
            ? 'speedReading/evaluation'
            : item.lxmc === '阅读训练'
                ? 'speedReading/training'
                : item.lxmc === '必读书籍'
                    ? 'speedReading/training'
                    : '';

        if (appPath) {
          this.$router.push({
            path: '/sw/home',
            query: {
              app: appPath,
              lxid: item.lxid,
              lxmc: item.lxmc
            }
          });
        }
      } else {
        this.dialogInfos.isShow = true
        this.dialogMsgs = '抱歉,请先开通会员~'
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      }
    },
    handleLoginOut() {
      this.$router.push("/sw/login");
    }
  }
}
;
</script>
<style lang="scss" scoped>
::v-deep .el-dropdown {
  vertical-align: top;
}

::v-deep .el-dropdown + .el-dropdown {
  margin-left: 15px;
}

::v-deep .el-icon-arrow-down {
  font-size: 12px;
}
</style>