<template>
  <div>
    <el-dialog
        title="忘记密码"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose"
    >
      <div class="dialog-content">
        <el-form ref="form" :model="formData" :rules="formRules" label-width="25px" style="height: 300px;background-color: #fff;width: 400px;margin-left:30px;
        backdrop-filter: blur(2px);">
          <el-form-item prop="yhm" style="padding-top: 30px">
            <el-input v-model="formData.yhm" autocomplete="off" placeholder="请输入手机号" clearable
                      style="width:330px;background-color: #f0f2f5;padding: 10px;margin-bottom: 10px"></el-input>
          </el-form-item>
          <el-form-item prop="yzm">
            <div style="display: flex;width: 350px;height: 60px">
              <el-input placeholder="请输入验证码"
                        style="width:40%;background-color: #f0f2f5;padding: 10px;margin: 5px 15px 10px 0 "
                        v-model="formData.yzm"
                        clearable></el-input>
              <el-button
                  style="color: orange;margin-top: 5px;margin-bottom: 10px;border: orange;cursor: pointer;width:60%; "
                  @click="obtainYzm"
                  :disabled="disableButton">
                {{ buttonText }}
              </el-button>
            </div>
          </el-form-item>
          <el-form-item prop="mm" style="padding-bottom: 5px">
            <el-input v-model="formData.mm" autocomplete="new-password" placeholder="请输入新密码"
                      :type="showPassword ? 'text' : 'password'"
                      style="width:330px;background-color: #f0f2f5;padding: 10px;margin-top: 10px;margin-bottom: 10px"></el-input>
            <svg-icon :icon="showPassword ? 'icon1' : 'icon2' " @click.native="togglePasswordVisibility"
                      style="position: absolute; top:210px ; right: 60px;display: inline-block"/>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose" style="margin-right: 20px;color: gray">取 消</el-button>
        <el-button @click="handleSubmit" style="color: white" type="primary">确 定</el-button>
      </div>
      <svg-icon :icon=" 'icon4' " style="position: absolute;top:16vh;left: 345px;cursor: pointer"
                @click.native="closeFn"></svg-icon>
    </el-dialog>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
  </div>
</template>
<script>
import {sendOnline, forgetPassword, alterPassword} from "@/api/login";

export default {
  data() {
    return {
      dialogInfos: {
        width: '24vw',
        isShow: false //是否开启进入阅读的动画
      },
      dialogMsgs: '',
      dialogVisible: false,
      buttonText: '获取验证码',
      disableButton: false,
      showPassword: false, // 是否显示密码
      yzmResult: '',
      formData: {
        yhm: '',
        mm: '',
        yzm: '',
        yhlx: 'ydUser',
        sjh: ''
      },
      formRules: {
        yhm: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        mm: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        yzm: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    closeFn() {
      this.handleClose()
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async obtainYzm() {
      if (!this.formData.yhm) {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `请先输入手机号`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
        return
      }
      const {resultCode, result} = await sendOnline({phone: this.formData.yhm})
      if (resultCode == 200) {
        this.yzmResult = result
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取成功`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      } else {
        this.dialogInfos.isShow = true
        this.dialogMsgs = `获取失败`
        setTimeout(() => {
          this.dialogInfos.isShow = false
        }, 2000)
      }

      // 禁用按钮并设置倒计时
      this.disableButton = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.disableButton = false;
          this.buttonText = '获取验证码';
        } else {
          this.buttonText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        this.formData.sjh = this.formData.yhm
        if (valid) {
          if (this.formData.yzm != this.yzmResult) {
            this.dialogInfos.isShow = true
            this.dialogMsgs = `请输入正确的验证码`
            setTimeout(() => {
              this.dialogInfos.isShow = false
            }, 2000)
            return
          }
          forgetPassword(this.formData)
              .then(res => {

                if (res.resultCode == 200) {

                  if (res.result != null) {
                    let param = {
                      appIdList: [],
                      classIdList: [],
                      roleIdList: [],
                      studentsIdList: [],
                      tenantsIdList: [],
                      user: {yhid: res.result.yhid, mm: this.formData.mm}
                    }
                    alterPassword(param).then((res) => {
                      if (res.resultCode === 200) {
                        this.dialogVisible = false;
                        this.$refs.form.resetFields();
                        this.dialogInfos.isShow = true
                        this.dialogMsgs = `修改成功`
                        setTimeout(() => {
                          this.dialogInfos.isShow = false
                        }, 2000)
                      } else {
                        this.dialogInfos.isShow = true
                        this.dialogMsgs = `修改失败`
                        setTimeout(() => {
                          this.dialogInfos.isShow = false
                        }, 2000)
                      }
                    })
                  } else {
                    this.dialogInfos.isShow = true
                    this.dialogMsgs = `该用户还未注册，请先注册`
                    setTimeout(() => {
                      this.dialogInfos.isShow = false
                    }, 2000)
                  }
                } else {
                  this.dialogInfos.isShow = true
                  this.dialogMsgs = res.resultDesc
                  setTimeout(() => {
                    this.dialogInfos.isShow = false
                  }, 2000)
                }
              })
              .catch(error => {
                this.dialogInfos.isShow = true
                this.dialogMsgs = `获取失败，请重试`
                setTimeout(() => {
                  this.dialogInfos.isShow = false
                }, 2000)
              });

        } else {
          return false;
        }
      });
    }
  }
};

</script>

<style lang="scss" scoped>
.dialog-content {
  margin-left: -30px;
}

.dialog-footer {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 15px;
  text-align: right;
  background-color: #f0f2f5;
}

::v-deep .el-dialog__footer {
  padding: 0;
}

</style>
