import axios from './index'
import qs from 'qs'
// 登出接口
export const loginOut = params => {
    return axios.post(`api/8210/user/loginOut`, qs.stringify(params))
}
export const loginOnline = (params) => {
    return axios.post(`api/8210/user/onLineLogin`, qs.stringify(params));
};
//短信登录
export const loginMobile = params => {
    return axios.post(`api/8210/user/mobileLogin`, qs.stringify(params))
}
// 新增线上用户
export const userOnline = (params) => {
    return axios.post(`api/8210/user/insertOnLineUser`, params);
};
//短信发送管理
export const sendOnline = (params) => {
    return axios.post(`api/8100/message/sendMessage`, params);
};
// 获取地区
export const getAreaList = (params) => {
    return axios.get(`api/8100/region/selectRegionBySjbm`, params);
};
export const forgetPassword = (params) => {
    return axios.post(`api/8210/user/selectUserForUpdate`, params);
};

export const alterPassword = (params) => {
    return axios.post(`api/8210/user/updateUser`, params);
};
